export const myPageQueryKeys = {
  all: ["MY_PAGE"],
  getProfile: (options?: object) => [
    ...myPageQueryKeys.all,
    "PROFILE",
    { options },
  ],
  getEventList: (options?: object) => [
    ...myPageQueryKeys.all,
    "EVENT_LIST",
    { options },
  ],
  getPendingReviewEventList: (options?: object) => [
    ...myPageQueryKeys.all,
    "EVENT_LIST",
    "PENDING_REVIEW",
    { options },
  ],
  eventListKey: ["MY_PAGE", "EVENT_LIST"],
  getReviewList: (options?: object) => [
    ...myPageQueryKeys.all,
    "REVIEW_LIST",
    { options },
  ],
  getReviewDetail: (options?: object) => [
    ...myPageQueryKeys.all,
    "REVIEW_DETAIL",
    { options },
  ],
  getReviewingEventSummary: (options?: object) => [
    ...myPageQueryKeys.all,
    "REVIEWING_EVENT_SUMMARY",
    { options },
  ],
  getProjectInfo: (options?: object) => [
    ...myPageQueryKeys.all,
    "PROJECT_INFO",
    { options },
  ],
};
