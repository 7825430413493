import { UseQueryOptions, useQuery } from "@enkor/react-query";

import { ApiResponseDto } from "@models/client";
import { ResponseUserDetail } from "@models/data-contracts";

import { authQueryKeys } from "../constants/authQueryKeys";
import { UserViewModel } from "../viewModels/UserViewModel";

import { fetchUserProfile } from "./authFetchHandler";

export const useFetchUserProfileQuery = (
  options?: Omit<
    UseQueryOptions<ApiResponseDto<ResponseUserDetail>>,
    "queryKey" | "queryFn" | "select"
  >,
) => {
  return useQuery({
    queryKey: authQueryKeys.getUserProfile(),
    queryFn: fetchUserProfile,
    select: ({ data }) => UserViewModel.create(data),
    ...options,
  });
};
