import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";

import { useQueryClient } from "@enkor/react-query";

import { NAVIGATION_ROUTE } from "@domains/common/constants/navigationRoute";
import useWebStorage, { STORAGE_KEY } from "@logics/hooks/useWebStorage";
import { myPageQueryKeys } from "@domains/myPage/constants/myPageQueryKeys";
import WebViewBridge from "@logics/utils/webViewBridge";
import { dataTrackEvent, identifyUser } from "@logics/utils/dataTrackEvent";
import { DATA_TRACKING_EVENT_NAME } from "@domains/common/constants/dataTrackEventName";

import { getAccessToken, removeAuthTokens } from "../utils/authTokenHandler";
import { useFetchUserProfileQuery } from "../network/authQueries";
import { authQueryKeys } from "../constants/authQueryKeys";

export const useAuthSession = () => {
  const router = useRouter();
  const [token, setToken] = useState<string>("");

  const queryClient = useQueryClient();

  const [, setRedirectUrl] = useWebStorage(
    STORAGE_KEY.AUTH_REDIRECT,
    "",
    "localStorage",
  );
  const { data: user } = useFetchUserProfileQuery({
    enabled: !!token,
  });

  const logout = () => {
    setToken("");
    removeAuthTokens();

    // TODO: user profile query 하나로 합치기
    queryClient.removeQueries({
      queryKey: myPageQueryKeys.getProfile(),
    });
    queryClient.removeQueries({
      queryKey: authQueryKeys.getUserProfile(),
    });

    dataTrackEvent({
      eventName: DATA_TRACKING_EVENT_NAME.AUTH.CLICK_LOGOUT,
    });

    window.localStorage.setItem(STORAGE_KEY.AUTH_TRIGGERED, "false");

    // NOTE: 페이지 라우팅 전 로그아웃 처리
    if (WebViewBridge.isInAppWebView()) {
      WebViewBridge.getInstance().triggerLogout();
      return;
    }

    if (router.asPath === NAVIGATION_ROUTE.EVENT_LIST.HREF) {
      router.reload();
    } else {
      router.push(NAVIGATION_ROUTE.EVENT_LIST.HREF);
    }
  };

  const guardRoute = useCallback(
    (callback: () => void) => {
      // login 안한 경우
      const redirectAsPath = router.asPath.slice(1);

      if (!token) {
        if (WebViewBridge.isInAppWebView()) {
          WebViewBridge.getInstance().navigateToEntry();
          WebViewBridge.getInstance().setStorageRedirectAsPath(
            `/${redirectAsPath}`,
          );
          return;
        }

        setRedirectUrl(redirectAsPath);

        router.push(NAVIGATION_ROUTE.AUTH_ENTRY.HREF);

        return;
      }

      // profile 작성 안한 경우
      if (!user?.phone) {
        if (WebViewBridge.isInAppWebView()) {
          WebViewBridge.getInstance().navigateToMyPageEditProfile();
          WebViewBridge.getInstance().setStorageRedirectAsPath(
            `/${redirectAsPath}`,
          );
          return;
        }

        setRedirectUrl(redirectAsPath);

        router.push(NAVIGATION_ROUTE.EDIT_PROFILE.HREF);

        return;
      }

      callback();
    },
    [router, token, user, setRedirectUrl],
  );

  useEffect(() => {
    const token = getAccessToken();

    if (!token) return;

    try {
      setToken(token);

      if (
        user &&
        !(window.localStorage.getItem(STORAGE_KEY.AUTH_TRIGGERED) === "true")
      ) {
        identifyUser(user);

        window.localStorage.setItem(STORAGE_KEY.AUTH_TRIGGERED, "true");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }, []);

  return {
    user,
    isUserLoggedIn: !!token,
    logout,
    guardRoute,
  };
};
