import {
  formatPhoneNumber,
  parsePhoneNumber,
  Value,
} from "react-phone-number-input";

import {
  formatDate,
  formatDiffDate,
  ignoreTimezone,
  isValid,
} from "@enkor/date-fns";

import { ResponseUserDetail } from "@models/data-contracts";
import { GenderKeyType, GenderValueType } from "@domains/common/types/common";
import { makeFlagEmoji } from "@logics/utils/makeFlagEmoji";
import countries from "@public/data/country.json";
import { GENDER_OPTION } from "@domains/common/constants/wordsMapper";
import { AuthProviderType } from "@models/client";

import { ReviewCardType } from "../types/reviewTypes";

export class UserViewModel {
  id: number;
  url: string;
  nickName: string;
  description: string;
  dob: string;
  national: string;
  gender: GenderKeyType;
  email: string;
  phone: string;
  interestCategories: number[];
  reviewInfo: {
    shouldShowReview: boolean;
    avgRating: string;
    totalCount: number;
  };
  reviewList: ReviewCardType[];
  hasProfile: boolean;
  authProvider: AuthProviderType;
  joinedCount: number;

  /** ex) 🇰🇷 Korea */
  nationalWithFlag: string;
  /** ex) +82 10-9989-9748 */
  formattedPhone: string;
  formattedDob: string;
  genderForDisplay: GenderValueType;

  isSuperHost: boolean;
  permission: ResponseUserDetail["permission"];

  static create(responseData: ResponseUserDetail) {
    const userViewModel = new UserViewModel();

    userViewModel.id = responseData.id;
    userViewModel.url = responseData.url;
    userViewModel.nickName = responseData.nickName;
    userViewModel.description = responseData.description;
    userViewModel.dob = responseData.dob;
    userViewModel.formattedDob = isValid(new Date(responseData.dob))
      ? formatDate({
          date: responseData.dob,
          type: "ENG_MONTH_DAY_YEAR",
        })
      : "";

    userViewModel.national = responseData.national;
    userViewModel.gender = responseData.gender;
    userViewModel.email = responseData.email;
    userViewModel.phone = responseData.phone;
    userViewModel.interestCategories = responseData.categoryList.map(
      ({ id }) => id,
    );

    userViewModel.genderForDisplay = GENDER_OPTION[responseData.gender];
    userViewModel.formattedPhone = this.formatPhoneForDisplay(
      responseData.phone,
    );
    userViewModel.nationalWithFlag = this.combineNationalWithFlag(
      responseData.national,
    );

    userViewModel.hasProfile = !!responseData.phone;

    userViewModel.authProvider = responseData.authProvider[0];

    userViewModel.reviewInfo = {
      shouldShowReview: !!responseData.reviewInfo.avgRating,
      avgRating: responseData.reviewInfo.avgRating.toFixed(1),
      totalCount: responseData.reviewInfo.totalCount,
    };

    userViewModel.reviewList = responseData.reviewInfo.list.map(
      (reviewInfo) => ({
        id: reviewInfo.id,
        review: {
          rating: reviewInfo.rating,
          content: reviewInfo?.content || "",
          fileList: reviewInfo.fileList,
          createdAt: formatDiffDate(ignoreTimezone(reviewInfo.createdAt)),
        },
        reviewer: {
          id: reviewInfo.createdUserId,
          nickname: reviewInfo.createdUserName,
          profile: reviewInfo.createdUserUrl,
        },
        event: {
          id: String(reviewInfo.eventId),
          title: reviewInfo.eventTitle,
        },
      }),
    );

    userViewModel.joinedCount = responseData.joinCount;

    userViewModel.isSuperHost = responseData.permission === "SUPER";
    userViewModel.permission = responseData.permission;

    return userViewModel;
  }

  private static combineNationalWithFlag(
    national: ResponseUserDetail["national"],
  ) {
    if (!national) return "";

    const flagEmoji = makeFlagEmoji(national);
    const countryName = countries[national];

    return `${flagEmoji} ${countryName}`;
  }

  private static formatPhoneForDisplay(phone: ResponseUserDetail["phone"]) {
    if (!phone) return "";

    const parsedPhoneNumber = parsePhoneNumber(phone);
    const formattedPhoneNumber = formatPhoneNumber(phone as Value);

    return `+${parsedPhoneNumber?.countryCallingCode} ${formattedPhoneNumber}`;
  }
}
